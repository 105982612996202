<template>
    <div class="w-full h-full flex flex-col bg-white overflow-y-auto website">
        
        <div class="w-full px-8">
            <div class="container mx-auto">
                <div class="flex items-center my-8 w-full">
                    <div class="flex-grow">
                        <img 
                            src="/assets/logo_dark.svg"
                            class="flex-none "
                        />
                    </div>
                    
                    <div 
                        class="block md:hidden"
                        @click="showMenu = !showMenu">
                        
                        <i class="fas fa-bars text-black text-2xl" />
                    </div>
                    <div class="hidden md:flex items-center justify-center">
                        <router-link 
                            class="link" 
                            to="/signin">
                            
                            {{$t('auth.signIn')}}
                        </router-link>
                        
                        <router-link 
                            class="button submit w-32 ml-8" 
                            to="signup">
                            
                            {{$t('auth.signUp')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="w-full my-8 px-8">
            <div class="mx-auto max-w-xl lg:max-w-3xl text-center">
                <h1 class="text-2xl md:text-4xl max-w-xl mx-auto text-clay-dark font-bold leading-tight text-center my-2">
                    {{$t('www.promo.heading')}} 
                </h1>
                <p class="my-0">
                    {{$t('www.promo.pitch')}} 
                </p>
                
                <!-- <div class="md:grid grid-cols-2 gap-8 mx-auto mt-8 max-w-sm items-start"> -->
                <div class="sm:flex mx-auto mt-8 justify-center">
                    <router-link 
                        class="bg-orange-500 inline-block text-xl rounded px-8 py-3 text-white mx-8" 
                        to="signup">
                        
                        {{$t('auth.signUp')}}
                    </router-link>
                    
                    <p class="mt-4">
                        {{$t('www.promo.freeText')}}
                    </p>
                </div>
                
                <p class="my-8">
                    <strong class="text-black">{{$t('www.promo.price')}}</strong>
                    {{$t('www.promo.priceText')}}<br>
                    <span>{{$t('www.promo.userPriceText')}}</span>
                </p>
            </div>
        </div>
        
        
        <div class="w-full animated">
            <div class="mx-auto mb-8 container text-center px-12 flex justify-center">
                <transition 
                    name="pop"
                    appear>
                    
                    <div class="bg-gray-800 shadow-xl rounded-xl p-2 max-w-4xl ">
                        <div class="mb-2 grid grid-cols-3 cap-4 w-12">
                            <div class="w-3 h-3 bg-gray-400 rounded-full" />
                            <div class="w-3 h-3 bg-gray-400 rounded-full opacity-50" />
                            <div class="w-3 h-3 bg-gray-400 rounded-full opacity-50" />
                        </div>
                        
                        <div class="overflow-hidden rounded">
                            
                            <picture 
                                class="w-full max-w-2xl rounded-lg "
                                alt="Image of GivviGo user interface">
                                
                                <source srcset="/assets/promo.webp" type="image/webp">
                                <source srcset="/assets/promo.png" type="image/png">
                                <img src="/assets/promo.png">
                            </picture>
                        </div>
                        
                    </div>
                </transition>
            </div>
        </div>
        
        
        
        <div class="w-full py-12 px-8 bg-cyan-dark text-white">
            <div class="mx-auto max-w-xl lg:max-w-3xl text-center">
                <h2 class="text-2xl md:text-4xl max-w-2xl mx-auto font-bold leading-tight text-center my-2">
                    {{$t('www.target.heading')}}
                </h2>
                
                <p class="my-0">
                    {{$t('www.target.text')}}
                </p>
            </div>
        </div>
        
        
        
        <div class="w-full px-8">
            <div class="mx-auto container my-12">
                <h3 class="text-2xl md:text-4xl max-w-2xl mx-auto text-clay-dark font-bold leading-tight text-center my-16">
                    {{$t('www.functions.heading')}}
                </h3>
                
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12">
                    <div class="">
                        <h3 class="text-black text-xl leading-tight my-4">
                            {{$t('www.functions.fullOverview')}}
                        </h3>
                        <p>
                            {{$t('www.functions.fullOverviewText')}}
                        </p>
                    </div>
                    <div class="">
                        <h3 class="text-black text-xl leading-tight my-4">
                            {{$t('www.functions.engageMore')}}
                        </h3>
                        <p>
                            {{$t('www.functions.engageMoreText')}}
                        </p>
                    </div>
                    <div class="">
                        <h3 class="text-black text-xl leading-tight my-4">
                            {{$t('www.functions.oneSystem')}}
                        </h3>
                        <p>
                            {{$t('www.functions.oneSystemText')}}
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="w-full p-8 bg-clay-dark text-white">
            <div class="mx-auto container text-center">
                <p class="opacity-50 my-8">
                    GivviGo is created in Norway by passionate people with background in sales and software development. 
                    We are based in <a class="text-yellow-400" href="https://www.google.com/maps?q=holmestrand&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjjnZXTx_jvAhWRAxAIHWI0CoUQ_AUoAXoECAEQAw" target="_blank">Holmestrand</a> and 
                    <a class="text-yellow-400" href="https://www.google.com/maps/place/Oslo/@59.8939225,10.7150776,12z/data=!3m1!4b1!4m5!3m4!1s0x46416e61f267f039:0x7e92605fd3231e9a!8m2!3d59.9138688!4d10.7522454" target="_blank">Oslo</a>.
                    <br>
                    Get in touch with us at <a class="text-yellow-400" href="mailto:hello@givvigo.com">hello@givvigo.com</a>.
                </p>
                <div class="">
                    Copyright © 2021 GivviGo. All rights reserved.
                </div>
                
            </div>
            
        </div>
        
        <div 
            v-if="showMenu"
            class="fixed top-0 left-0 right-0 bottom-0 bg-white text-black p-8">
            
            <div class="flex flex-col h-full items-center justify-center">
                <img 
                    src="/assets/logo_dark.svg"
                    class="mb-12"
                />
                
                <router-link 
                    class=" w-full text-center p-3 border rounded-lg border-cyan mb-6" 
                    to="/signin">
                    
                    Sign In
                </router-link>
                
                <router-link 
                    class=" w-full text-center p-3  rounded-lg bg-cyan text-white" 
                    to="signup">
                    
                    Sign Up
                </router-link>
            </div>
                
            
            <div 
                class="close text-gray hover:text-black absolute top-0 right-0 m-12" 
                @click="showMenu = false;">
                
                <i class="fas fa-times text-2xl" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showMenu: false,
            }
        },
    }
</script>

<style lang="scss">
    .website {
        .animated{
            @apply w-full;
            background-image: url('/assets/bg-animated.svg');
            background-position: center center;
            background-size: cover;
        }
    }
</style>
